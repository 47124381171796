import React from 'react'
import classes from "./Wmd.module.css";
import f from "./../../Assest/dke.avif"

const Wmd = () => {
  return (
    <div>
       <div className={classes.container}>
            <div className={classes.leftPanel}>
                <h1 className={classes.title}>What Makes EshikaTech Different?</h1>
                <p className={classes.text}>
                    EshikaTech provides all your digital needs under one roof. We deliver top-quality UI/UX designs and comply with quality standards. Whether you are a start-up or a large enterprise in an industry, we have the expertise and innovative approach to assist you. Our team of highly motivated, result-driven professionals creates effective strategies for developing products. We helped our clients achieve their business objectives with our strategically developed products.
                </p>
                <p className={classes.text}>
                    As part of our development and design process, we ensure in-depth analysis and planning to increase sales, build your brand, and reach out to your potential customers faster.
                </p>
                <br/><br/><br/>
            </div>
            <div className={classes.rightPanel}>
                <img 
                    src={f}
                    alt="A digital representation of eshikatech" 
                    className={classes.image} 
                />
            </div>
        </div>
    </div>
  )
}

export default Wmd
