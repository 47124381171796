import React from "react";
import {Link} from "react-router-dom"
import classes from "./Service.module.css";
import hb from "./../../Assest/j.png"
import hb2 from "./../../Assest/l.png"
import hb3 from "./../../Assest/f.png"
import Fade from 'react-reveal/Fade';

import k2 from "./../../Assest/webdev.gif"
import k1 from "./../../Assest/resp.gif"
import k3 from "./../../Assest/wec.gif"
import k4 from "./../../Assest/ec.gif"
import k5 from "./../../Assest/cms.gif"
import k6 from "./../../Assest/fwd.gif"
import k7 from "./../../Assest/social.gif"
import k8 from "./../../Assest/gd.gif"
import k9 from "./../../Assest/J.gif"


import { FaArrowRight } from 'react-icons/fa';
const Service =() =>{

const services = [
       {
        title: "Website Development",
        description: "Start Developing Your Own Professional Website with our advanced Tools. Get the Flexibility You Need to Create Without Limits and Stand Out Online.",
        image: k2,
        alt: "Web UX/UI illustration with a computer monitor displaying various web design elements"
    },
        {
        title: "Responsive Design",
        description: "If you are planning a mobile strategy for your organization, we can help you make informed decisions with the right project plan to match your goals.",
        image: k1,
        alt: "Mobile UX/UI illustration with a smartphone displaying various app elements"
    },
    
      {
        title: "WebApp",
        description: "Start building your professional website using our advanced web app! Enjoy the flexibility to create without limits, allowing you to design a unique online presence.",
        image: k3,
        alt: "Web UX/UI illustration with a computer monitor displaying various web design elements"
    },
    {
        title: "E-Commerce Development",
        description: "We design and develop custom ecommerce websites that increase conversion rates and online visibility. Browse our case studies, testimonials and samples.",
        image: k4,
        alt: "E-Commerce Development illustration with various icons representing online shopping"
    },
       {
        title: "CMS",
        description: "Take control of your online presence with our powerful CMS. Our CMS offers the flexibility you need to create, manage, and update your website seamlessly. ",
        image: k5,
        alt: "Web UX/UI illustration with a computer monitor displaying various web design elements"
    },
    {
        title: "Full Stack Websites",
        description: "Transform your ideas into reality with our full stack website development tools! Enjoy the freedom to create a fully functional, responsive site tailored to your needs.  ",
        image: k6,
        alt: "Web UX/UI illustration with a computer monitor displaying various web design elements"
    },

  {
        title: "Social Media Marketing",
        description: "Transform your online presence with tailored social media marketing strategies. Let us help you engage your audience and drive results!",
        image: k7,
        alt: "Web UX/UI illustration with a computer monitor displaying various web design elements"
    },

      {
        title: "Graphic Design",
        description: "Bring your vision to life with stunning graphic design that captivates and communicates. From branding to digital assets, we create visuals that stand out!",
        image: k8,
        alt: "Web UX/UI illustration with a computer monitor displaying various web design elements"
    },

      {
        title: "Logo Creation and Branding",
        description: "Craft a memorable identity with our logo creation and branding services. We design logos that reflect your essence and make a lasting impression!",
        image: k9,
        alt: "Web UX/UI illustration with a computer monitor displaying various web design elements"
    },

];

    return(
<>
{/* <div  className={classes.block}>

<div className={classes.rect}>
    <Fade left>
<div className={classes.rect1}>
    <img src={hb}  className={classes.im1} />
    
</div>
<div className={classes.rect2}>
<h3  className={classes.ih4}>Website Design & Development</h3>
<p  className={classes.ih41}>Our Team is constantly pushing the boundaries of current thinking to deliver cutting-edge user experiences and user interfaces as an exceptional web design business.</p>
<Link to='/contact'  className={classes.b22}><button   className={classes.b2}>Contact Us</button></Link>

</div>
</Fade>
</div>


<div className={classes.rect}>
        <Fade right>
    <div className={classes.rect2}>
<h3  className={classes.ih4}>Domain & Hosting</h3>
<p  className={classes.ih41}>A domain and hosting service gives you your business website with an online presence and storage.</p>
<Link to='/contact'  className={classes.b22}><button className={classes.b2}>Contact Us</button></Link>
</div>
<div className={classes.rect1}>
    <img src={hb2}  className={classes.im2} />
</div>
</Fade>
</div>


<div className={classes.rect}>
    <Fade left>
<div className={classes.rect1}>
    <img src={hb3}  className={classes.im1} />
</div>
<div className={classes.rect2}>
<h3  className={classes.ih4}>Content Management System</h3>
<p  className={classes.ih41}>Our content management service offers a comprehensive solution for businesses and individuals to efficiently manage their digital content with help of wordpress.</p>
<Link to='/contact'  className={classes.b22}><button   className={classes.b2}>Contact Us</button></Link>
</div>
</Fade>
</div>
</div> */}



        <div className={classes.container}>
            <div className={classes.grid}>
                {services.map((service, index) => (
                    <div key={index} className={classes.card}>
                        <div className={classes.imageContainer}>
                            <img src={service.image} alt={service.alt} className={classes.image} />
                            <div className={classes.buttonContainer}>
                                <button className={classes.button}>
                                  <FaArrowRight />
                                </button>
                            </div>
                        </div>
                        <div className={classes.content}>
                            <h2 className={classes.title}>{service.title}</h2>
                            <p className={classes.description}>{service.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
      </> 
    );
};





export default Service; 