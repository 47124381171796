import React,{useEffect} from 'react'

import Banner from "./../Component/About/Banner"
import Ab2 from "./../Component/About/Ab2.js"
import Ab1 from "./../Component/About/Ab1.js"
import Dna from "./../Component/About/Dna"
import Wmd from "./../Component/About/Wmd"
import Oc from "./../Component/About/Oc"
import Tm from "./../Component/About/Testimonials"
import Tm2 from "./../Component/About/Testimonials2"
import Counter from "./../Component/About/Counter.js"


const About = () => {
useEffect(() => {
  window.scrollTo(0, 0);

  // About Page Meta Tags
  document.title = 'Eshikatech: About Us | Innovative Tech Solutions';

  // Set meta description dynamically
  const aboutMetaDescription = document.createElement('meta');
  aboutMetaDescription.name = 'description';
  aboutMetaDescription.content = 'Learn more about Eshikatech, a leader in providing innovative tech solutions. Discover our mission, values, and the team behind our success.';
  document.head.appendChild(aboutMetaDescription);

  // Set meta keywords dynamically
  const aboutMetaKeywords = document.createElement('meta');
  aboutMetaKeywords.name = 'keywords';
  aboutMetaKeywords.content = 'Eshikatech, About Us, Tech Solutions, Innovative Technology, Tech Experts, IT Services';
  document.head.appendChild(aboutMetaKeywords);

  // Set canonical link dynamically
  const aboutCanonicalLink = document.createElement('link');
  aboutCanonicalLink.rel = 'canonical';
  aboutCanonicalLink.href = 'https://eshikatech.com/about';
  document.head.appendChild(aboutCanonicalLink);

  // Set Open Graph title dynamically
  const aboutOgTitle = document.createElement('meta');
  aboutOgTitle.property = 'og:title';
  aboutOgTitle.content = 'Eshikatech: About Us | Innovative Tech Solutions';
  document.head.appendChild(aboutOgTitle);

  // Set Open Graph description dynamically
  const aboutOgDescription = document.createElement('meta');
  aboutOgDescription.property = 'og:description';
  aboutOgDescription.content = 'Learn more about Eshikatech, a leader in providing innovative tech solutions. Discover our mission, values, and the team behind our success.';
  document.head.appendChild(aboutOgDescription);

  // Set Open Graph URL dynamically
  const aboutOgUrl = document.createElement('meta');
  aboutOgUrl.property = 'og:url';
  aboutOgUrl.content = 'https://eshikatech.com/about';
  document.head.appendChild(aboutOgUrl);

  // Set Open Graph site name dynamically
  const aboutOgSiteName = document.createElement('meta');
  aboutOgSiteName.property = 'og:site_name';
  aboutOgSiteName.content = 'Eshikatech Pvt Ltd';
  document.head.appendChild(aboutOgSiteName);

  // Set Open Graph image dynamically
  const aboutOgImage = document.createElement('meta');
  aboutOgImage.property = 'og:image';
  aboutOgImage.content = 'https://eshikatech.com/lo.jpeg';
  document.head.appendChild(aboutOgImage);

  // Set Open Graph secure image URL dynamically
  const aboutOgImageSecure = document.createElement('meta');
  aboutOgImageSecure.property = 'og:image:secure_url';
  aboutOgImageSecure.content = 'https://eshikatech.com/lo.jpeg';
  document.head.appendChild(aboutOgImageSecure);

  // Set Open Graph image width dynamically
  const aboutOgImageWidth = document.createElement('meta');
  aboutOgImageWidth.property = 'og:image:width';
  aboutOgImageWidth.content = '300';
  document.head.appendChild(aboutOgImageWidth);

  // Set Open Graph image height dynamically
  const aboutOgImageHeight = document.createElement('meta');
  aboutOgImageHeight.property = 'og:image:height';
  aboutOgImageHeight.content = '200';
  document.head.appendChild(aboutOgImageHeight);

  // Cleanup on component unmount

   // Add Structured Data for About Page
    const aboutStructuredData = document.createElement('script');
    aboutStructuredData.type = 'application/ld+json';
    aboutStructuredData.textContent = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "AboutPage",
      "url": "https://eshikatech.com/about",
      "name": "Eshikatech About Us",
      "description": "Learn more about Eshikatech, a leader in providing innovative tech solutions.",
      "publisher": {
        "@type": "Organization",
        "name": "Eshikatech Pvt Ltd",
        "logo": {
          "@type": "ImageObject",
          "url": "https://eshikatech.com/lo.jpeg"
        },
        "description": "Eshikatech offers cutting-edge technology solutions and services to help your business thrive. Discover our innovative approach to tech.",
  "inLanguage": "en",
    "sitelinks": [
    {
      "@type": "WebPage",
      "name": "Home",
      "url": "https://eshikatech.com"
    },
    {
      "@type": "WebPage",
      "name": "Services",
      "url": "https://eshikatech.com/service"
    },
    {
      "@type": "WebPage",
      "name": "About Us",
      "url": "https://eshikatech.com/about"
    },
    {
      "@type": "WebPage",
      "name": "Contact",
      "url": "https://eshikatech.com/Contact"
    }
  ]
      }
    });
    document.head.appendChild(aboutStructuredData);


  return () => {
    document.title = 'Eshikatech: About Us | Innovative Tech Solutions';
    document.head.removeChild(aboutMetaDescription);
    document.head.removeChild(aboutMetaKeywords);
    document.head.removeChild(aboutCanonicalLink);
    document.head.removeChild(aboutOgTitle);
    document.head.removeChild(aboutOgDescription);
    document.head.removeChild(aboutOgUrl);
    document.head.removeChild(aboutOgSiteName);
    document.head.removeChild(aboutOgImage);
    document.head.removeChild(aboutOgImageSecure);
    document.head.removeChild(aboutOgImageWidth);
    document.head.removeChild(aboutOgImageHeight);
        document.head.removeChild(aboutStructuredData);
  };

}, []);

  return (
    <div>

<Banner/>
<Ab1/>
<Dna/>
<Wmd/>
<Counter/>
<Tm/>
<Tm2/>
<Oc/>
<Ab2/>


    </div>
  )
}

export default About