import React from 'react'
import classes from "./Banner.module.css"
const Banner = () => {
  return (
    <div>


<div className={classes.banner}>

<h2  className={classes.ih4}>About Us</h2>
<h4 className={classes.ih41}><a href="https://eshikatech.com" className={classes.ih41}>Home </a>| <a href="https://eshikatech.com/about" className={classes.ih41}>About Us</a></h4> 
{/* <h2  className={classes.ih4}>Get To Know EshikaTech</h2> */}

</div>

    </div>
  )
}

export default Banner