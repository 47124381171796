import React, { useEffect, useRef } from 'react';
import classes from "./Big.module.css"
const LogoViewer = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (iframeRef.current) {
        iframeRef.current.style.height = `${window.innerHeight}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial height

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={classes.ddd}>
      <iframe
        ref={iframeRef}
        // src="https://eshikatech.com/logo.html"
       src="https://eshikatech.com/logo.html"
       className={classes.ddd1}
        title="EshikaTech Logo"
      />
    </div>
  );
};

export default LogoViewer;