import React from "react";
import classes from './ourclients.module.css';
import Slider from "react-slick";
import Patron from "./ourclientsprops";

 import rs1 from './../../Assest/s1.png';
 import rs2 from './../../Assest/s2.png';
import rs3 from './../../Assest/s3.png';
import rs4 from './../../Assest/s4.png';
// import rs4 from './../../Assest/rs4.jpeg';
// import rs5 from './../../Assest/rs5.jpeg';
// import rs6 from './../../Assest/rs6.jpeg';
// import rs7 from './../../Assest/rs7.jpeg';
// import rs8 from './../../Assest/rs8.jpeg';
// import rs9 from './../../Assest/rs9.jpeg';
// import rs10 from './../../Assest/rs10.jpeg';
// import rs11 from './../../Assest/rs11.jpeg';
// import rs12 from './../../Assest/rs12.jpeg';
// import rs13 from './../../Assest/rs13.jpeg';
// import rs14 from './../../Assest/rs14.jpeg';
// import rs15 from './../../Assest/rs15.jpeg';
// import rs16 from './../../Assest/rs16.jpeg';
// import rs17 from './../../Assest/rs17.jpeg';
// import rs18 from './../../Assest/rs18.jpeg';
// import rs19 from './../../Assest/rs19.jpeg';
// import rs20 from './../../Assest/rs20.jpeg';
// import rs21 from './../../Assest/rs21.jpeg';
// import rs22 from './../../Assest/rs22.jpeg';
// import rs23 from './../../Assest/rs23.jpeg';
// import rs24 from './../../Assest/rs24.jpeg';
// import rs25 from './../../Assest/rs25.jpeg';
// import rs26 from './../../Assest/rs26.jpeg';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Patronslider = () => {
  const settings = {
        dots: false,
        infinite: true,
        speed: 8000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                speed: 8000,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false  
              }
            }
        ]
    };


    return (
        <div className={classes.hold}>

            <h2 className={classes.ih3h}>Our Projects</h2>
      <center>
                <Slider {...settings} className={classes.hold2}>
                    <div className={classes.ls}>
                       <a href="https://oneroof.eshikatech.com/"> <Patron img={rs2} /> </a>
                    </div>
                    <div className={classes.ls}>
                       <a href="https://rajeshwree.eshikatech.com/"><Patron img={rs1} /> </a> 
                    </div>
                    <div className={classes.ls}>
                       <a href="https://rohitarts.com">  <Patron img={rs3} /> </a>
                    </div>
                    <div className={classes.ls}>
                       <a href="https://nmt.eshikatech.com">  <Patron img={rs4} /> </a>
                    </div>
      
                </Slider>
                  </center>
            </div>

    );
}

export default Patronslider;
