import React,{useEffect} from 'react'
import Contacts from "./../Component/Contact/Contact1"
import Banner from "./../Component/Contact/Banner"

const Contact = () => {
useEffect(() => {
  window.scrollTo(0, 0);

  // Contact Page Meta Tags
  document.title = 'Eshikatech: Contact Us | Get in Touch with Our Tech Experts';

  // Set meta description dynamically
  const contactMetaDescription = document.createElement('meta');
  contactMetaDescription.name = 'description';
  contactMetaDescription.content = 'Get in touch with Eshikatech for any inquiries or support. Our tech experts are here to help you with your technology needs and solutions.';
  document.head.appendChild(contactMetaDescription);

  // Set meta keywords dynamically
  const contactMetaKeywords = document.createElement('meta');
  contactMetaKeywords.name = 'keywords';
  contactMetaKeywords.content = 'Eshikatech, Contact Us, Tech Support, Technology Inquiries, IT Solutions, Contact Information';
  document.head.appendChild(contactMetaKeywords);

  // Set canonical link dynamically
  const contactCanonicalLink = document.createElement('link');
  contactCanonicalLink.rel = 'canonical';
  contactCanonicalLink.href = 'https://eshikatech.com/contact';
  document.head.appendChild(contactCanonicalLink);

  // Set Open Graph title dynamically
  const contactOgTitle = document.createElement('meta');
  contactOgTitle.property = 'og:title';
  contactOgTitle.content = 'Eshikatech: Contact Us | Get in Touch with Our Tech Experts';
  document.head.appendChild(contactOgTitle);

  // Set Open Graph description dynamically
  const contactOgDescription = document.createElement('meta');
  contactOgDescription.property = 'og:description';
  contactOgDescription.content = 'Get in touch with Eshikatech for any inquiries or support. Our tech experts are here to help you with your technology needs and solutions.';
  document.head.appendChild(contactOgDescription);

  // Set Open Graph URL dynamically
  const contactOgUrl = document.createElement('meta');
  contactOgUrl.property = 'og:url';
  contactOgUrl.content = 'https://eshikatech.com/contact';
  document.head.appendChild(contactOgUrl);

  // Set Open Graph site name dynamically
  const contactOgSiteName = document.createElement('meta');
  contactOgSiteName.property = 'og:site_name';
  contactOgSiteName.content = 'Eshikatech Pvt Ltd';
  document.head.appendChild(contactOgSiteName);

  // Set Open Graph image dynamically
  const contactOgImage = document.createElement('meta');
  contactOgImage.property = 'og:image';
  contactOgImage.content = 'https://eshikatech.com/lo.jpeg';
  document.head.appendChild(contactOgImage);

  // Set Open Graph secure image URL dynamically
  const contactOgImageSecure = document.createElement('meta');
  contactOgImageSecure.property = 'og:image:secure_url';
  contactOgImageSecure.content = 'https://eshikatech.com/lo.jpeg';
  document.head.appendChild(contactOgImageSecure);

  // Set Open Graph image width dynamically
  const contactOgImageWidth = document.createElement('meta');
  contactOgImageWidth.property = 'og:image:width';
  contactOgImageWidth.content = '300';
  document.head.appendChild(contactOgImageWidth);

  // Set Open Graph image height dynamically
  const contactOgImageHeight = document.createElement('meta');
  contactOgImageHeight.property = 'og:image:height';
  contactOgImageHeight.content = '200';
  document.head.appendChild(contactOgImageHeight);

  // Cleanup on component unmount

    const contactStructuredData = document.createElement('script');
    contactStructuredData.type = 'application/ld+json';
    contactStructuredData.textContent = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "ContactPage",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91-9967190248",
        "contactType": "Customer Service",
        "email": "info@eshikatech.com",
        "availableLanguage": "English",
        "description": "Eshikatech offers cutting-edge technology solutions and services to help your business thrive. Discover our innovative approach to tech.",
  "inLanguage": "en",
    "sitelinks": [
    {
      "@type": "WebPage",
      "name": "Home",
      "url": "https://eshikatech.com"
    },
    {
      "@type": "WebPage",
      "name": "Services",
      "url": "https://eshikatech.com/service"
    },
    {
      "@type": "WebPage",
      "name": "About Us",
      "url": "https://eshikatech.com/about"
    },
    {
      "@type": "WebPage",
      "name": "Contact",
      "url": "https://eshikatech.com/Contact"
    }
  ]
      }
    });
  document.head.appendChild(contactStructuredData);

  return () => {
    document.title = 'Eshikatech: Contact Us | Get in Touch with Our Tech Experts';
    document.head.removeChild(contactMetaDescription);
    document.head.removeChild(contactMetaKeywords);
    document.head.removeChild(contactCanonicalLink);
    document.head.removeChild(contactOgTitle);
    document.head.removeChild(contactOgDescription);
    document.head.removeChild(contactOgUrl);
    document.head.removeChild(contactOgSiteName);
    document.head.removeChild(contactOgImage);
    document.head.removeChild(contactOgImageSecure);
    document.head.removeChild(contactOgImageWidth);
    document.head.removeChild(contactOgImageHeight);
      document.head.removeChild(contactStructuredData);

  };

}, []);

  return (
    <div>
      <Banner/>
<Contacts/>
    </div>
  )
}

export default Contact