import React from 'react'
import classes from "./Banner.module.css"
const Banner = () => {
  return (
    <div>


<div className={classes.banner}>

<h2  className={classes.ih4}>Services</h2>
<h4 className={classes.ih41}><a href="https://eshikatech.com" className={classes.ih41}>Home </a>| <a href="https://eshikatech.com/service" className={classes.ih41}>Services</a></h4> 

</div>

    </div>
  )
}

export default Banner