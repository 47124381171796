import React from 'react'
import classes from "./Oc.module.css";
import A from "./../../logo/Rajeshwaree.png"
import B from "./../../logo/nmt.png"
import C from "./../../logo/oneroof.png"
import D from "./../../logo/rohitarts.png"

const Oc = () => {
  return (
    <div>

 <div className={classes.textCenter}>
            <h1 className={classes.title}>
                We Serve <span className={classes.highlight}>Clients</span> Across The Globe
            </h1>
            <p className={classes.description}>
                We are an experienced web design and development company located in Mumbai, India. Since 2023, we have built custom websites and helped businesses grow online – some of our clients have been with us from the very beginning.
            </p>
            <div className={classes.logoGrid}>
               <div className={classes.logoContainer}><img src={A} alt="Rajeshwaree Packaging" className={classes.logo1} /></div>
                <div className={classes.logoContainer}> <img src={B} alt="Nmt Packaging" className={classes.logo2} /></div>
                 <div className={classes.logoContainer}><img src={C} alt="OneRoof Solutions" className={classes.logo} /></div>
                 <div className={classes.logoContainer}><img src={D} alt="Rohit Arts" className={classes.logo} /></div>
                 </div>
        </div>


    </div>
  )
}

export default Oc