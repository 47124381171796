import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fade from "react-reveal/Fade"
import lotus from './../../Assest/yw.png';
import telephone from './../../Assest/telephone (1).png';
import communication from './../../Assest/communication.png';
import twitter from './../../Assest/twitter (1).png';
import facebook from './../../Assest/facebook (1).png';
import instagram from './../../Assest/social.png';
import whatsapp from './../../Assest/whatsapp (1).png';
import mp from './../../Other Docu/Eshikatechcompanyprofile.pdf';




import { FaHouseDamage, FaAddressCard, FaList, FaAddressBook, FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";
import './Nav.css';

const NavBar = () => {
    const [active, setActive] = useState("nav__menu");
    const [icon, setIcon] = useState("nav__toggler");
    const [scrolled, setScrolled] = useState(false);

    const navToggle = () => {
        if (active === "nav__menu") {
            setActive("nav__menu nav__active");
        } else {
            setActive("nav__menu");
        }

        if (icon === "nav__toggler") {
            setIcon("nav__toggler toggle");
        } else {
            setIcon("nav__toggler");
        }
    };

    const resetNavbar = () => {
        setActive('nav__menu');
        setIcon('nav__toggler');
    };

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="nasv">
            <Fade>
            <nav className={`nav ${scrolled ? 'nav--scrolled' : ''}`}>
                {/* <div className='header'>
                    <div className='h1'>

                    </div>
                    <div className='ss'>
                        <div>
                          <a href="tel:9967190248 " className='mail mail1'>   Phone: +91 9967190248 </a>
                        </div>
                        <div>

                            <a href="mailto:info@eshikatech.com" className='mail mail2'>Email: info@eshikatech.com</a>
                        </div>
                        <div className='yz'>

                            <FaInstagram className='icon3' />
                            <FaFacebook className='icon3' />
                            <FaLinkedin className='icon3' />


                        </div>
                    </div>
                </div> */}
                <div className='header2'>
                    <div className='logo2'>
                        <center>
                          <img src={lotus} className='lo'></img> 

                            {/* <h1 className='dke'>EshikaTech Pvt Ltd</h1> */}
                        </center>
                    </div>
                    <div className='nd'>
                        <div className={active}>
                            <ul className='ul'>
                                <li className="list"><Link to='/' className="list" onClick={resetNavbar}>{/*<FaHouseDamage className='icon2' />*/}&nbsp;Home</Link></li>
                                <li className="list"><Link to='/about' className="list" onClick={resetNavbar}>{/*<FaAddressCard className='icon2' />*/}&nbsp;About</Link></li>
                                <li className="list"><Link to='/service' className="list" onClick={resetNavbar}>{/*<FaList className='icon2' />*/}&nbsp;Services</Link></li>
                                    {/* <div className="dropdown-content">
                                        <Link to='/Full_Home_Interiors' className="lista" onClick={resetNavbar}>Full Home Interiors</Link>
                                        <Link to='/Commercial_Interiors' className="lista" onClick={resetNavbar}>Commercial Interiors</Link>
                                        <Link to='/Turnkey_Projects' className="lista" onClick={resetNavbar}>Turnkey Projects</Link>
                                    </div>  
                                <li className="list"><Link to='/gallery' className="list" onClick={resetNavbar}>{/*<FaList className='icon2' />* /}&nbsp;Gallery</Link></li> */}
                                <li className="list"><Link to='/Contact' className="list" onClick={resetNavbar}>{/*<FaAddressBook className='icon2' />*/}&nbsp;Contact Us</Link></li>
                                <li className="list"><a href='tel:9967190248' className="list" onClick={resetNavbar}>{/*<FaAddressBook className='icon2' />*/}&nbsp;<button className='hif1'>+91 9967190248</button></a></li>
                                <li className="list"><a href='mailto:info@eshikatech.com' className="list" onClick={resetNavbar}>{/*<FaAddressBook className='icon2' />*/}&nbsp;<button className='hif1'>info@eshikatech.com</button></a></li>
                                {/* <li className="list"><a href={mp} download className="list" onClick={resetNavbar}>&nbsp;<button className='hif'>Prospectus</button></a></li> */}
                                                                <li className="list"><a href={mp} download className="list" onClick={resetNavbar}>{/*<FaAddressBook className='icon2' />*/}&nbsp;<button className='hif'>Brochure</button></a></li>
                             {/* <div className='yz'>

                            <FaInstagram className='icon3' />
                            <FaFacebook className='icon3' />
                            <FaLinkedin className='icon3' />


                        </div> */}
                               
                            </ul>
                        </div>
                    </div>
                       <div className='nd3'>
                    <div onClick={navToggle}  className={`icon ${icon}`}>
                        <div className="line1"></div>
                        <div className="line2"></div>
                        <div className="line3"></div>
                    </div>
                    </div>
                </div>
            </nav>
            </Fade>
        </div>
    );
};

export default NavBar;


/*

  <a href="tel:9766554435" className='mail mail1'>/ 9766554435</a>


 <img src={telephone} className='icon2 jj' />    <img src={communication} className='icon3 jj' />

     <a href=''><img src={twitter} className='ims'></img></a>
                        <a href=''><img src={facebook} className='ims'></img></a>
                        <a href=''><img src={instagram} className='ims'></img></a>
                        <a href=''><img src={whatsapp} className='ims'></img></a>

  */