import React from 'react'
import classes from "./Contact1.module.css"
import { FaUser, FaPhone, FaEnvelope, FaComment } from 'react-icons/fa';

import a from "./../../Assest/yx.png"
import b from "./../../Assest/yy.png"
import c from "./../../Assest/yz.png"



const Contact1 = () => {
  return (
    <div>

<div className={classes.tc}>



<div className={classes.tc1}>

{/* 
           <a href="tel:+91 9967190248"  className={classes.sp}>
             <div className={classes.subDiv}>
                <img src={a} alt="Phone Icon" className={classes.si}/>
                <span className={classes.sp}>+91 9967190248</span>
            </div>
            </a>


              <a href="mailto:info@eshikatech.com"  className={classes.sp}>
            <div className={classes.subDiv}>
                <img src={b} alt="Email Icon" className={classes.si}/>
                <span className={classes.sp}>info@eshikatech.com</span>
            </div>
            </a>

       <a href="https://wa.me/9970568912" className={classes.sp}>
            <div className={classes.subDiv}>
                <img src={c} alt="Address Icon" className={classes.si}/>
                <span className={classes.sp}>Kandiwali East, Mumbai, 400101</span>
            </div>
     </a> */}


<div className={classes.container}>
            <div className="mb-4">
                <img 
                    src="https://eshikatech.com/static/media/yw.9a007ef279938dc9e30d.png" 
                    alt="Eshikatech Logo" 
                    className={classes.logo} 
                />
            </div>
            <h1 className={classes.title}>CORPORATE OFFICE</h1>
            <a href="https://eshikatech.com"  className={classes.sp}><h2 className={classes.subtitle}>EshikaTech Private Limited</h2></a>
            <p className={classes.address}>Kandiwali East, Mumbai, 400101,</p>
            <a href="tel:+91 9967190248"  className={classes.sp}> <p className={classes.contact}>Contact: +91 9967190248</p></a>
            <a href="mailto:info@eshikatech.com"  className={classes.sp}><p className={classes.contact}>Mail: info@eshikatech.com</p></a>
            <a href="https://wa.me/9970568912" className={classes.sp}><p className={classes.contact}>Whatsapp: +91 9967190248</p></a>
        </div>
</div>


<div className={classes.tc2}>

 <form className={classes.form} action="https://formsubmit.co/info@eshikatech.com" method="POST">
      <h1 className={classes.title2}>Please Feel Free To Ask</h1>
      <div className={classes.inputGroup}>
        <FaUser className={classes.icon} />
        <input
          type="text"
          pattern="[A-Za-z]+"
          className={classes.input}
          placeholder="Your Name"
        />
      </div>
      <div className={classes.inputGroup}>
        <FaPhone className={classes.icon} />
        <input
          type="tel"
          pattern="\d{10}"
          className={classes.input}
          placeholder="Mobile Number"
        />
      </div>
      <div className={classes.inputGroup}>
        <FaEnvelope className={classes.icon} />
        <input
          type="email"
          className={classes.input}
          placeholder="Your Email"
        />
      </div>
      <div className={classes.inputGroup}>
        <FaComment className={classes.icon} />
        <textarea
          className={classes.textarea}
          placeholder="Your Message"
        />
      </div>
      <button type="submit" className={classes.hif}>
        Send
      </button>
    </form>

</div>


</div>


    </div>
  )
}

export default Contact1