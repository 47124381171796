import React from 'react'
import classes from "./Dna.module.css"
import Fade from 'react-reveal/Fade';
import A from "./../../Assest/dba.png"


const Dna = () => {
  return (
<div>


    <div className={classes.bbd}>

  <h2 className={classes.ih4d}>
<br/><br/>
Foundation of Our Principles
<br/><br/>
</h2>


</div>

 
<div className={classes.steps}>
  <Fade leftt>

<div className={classes.s1}> 

<div className={classes.s11}> 
  <h3 className={classes.ih4}>Excellence in Operations </h3>
  <p className={classes.ih41}>We aim for top-tier technology solutions that enhance efficiency and effectiveness across all operations. </p>
</div>


<div className={classes.s11}> 
  <h3 className={classes.ih4}>Innovative Mindset</h3>
  <p className={classes.ih41}>We actively seek out and adopt new tools and techniques to drive creativity and develop pioneering solutions that address market needs. </p>
</div>


<div className={classes.s11}> 
  <h3 className={classes.ih4}>Unique Positioning </h3>
  <p className={classes.ih41}>By harnessing cutting-edge technologies, we strive to create a distinct presence in the industry, delivering unique value that differentiates us. </p>
</div>


<div className={classes.s11}> 
  <h3 className={classes.ih4}>Commitment to Quality</h3>
  <p className={classes.ih41}>Our dedication is to offer our clients services that adhere to the highest quality benchmarks, ensuring reliability and satisfaction. </p>
</div>


<div className={classes.s11}> 
  <h3 className={classes.ih4}>Seamless Processes</h3>
  <p className={classes.ih41}>Our streamlined processes are designed to meet our clients’ immediate and future requirements, promoting agility and responsiveness. </p>
</div>



</div>
</Fade>


 <Fade right>
<div className={classes.s2}>

<img src={A} className={classes.imd} />
    
    </div>

</Fade>


</div>


    </div>
  )
}

export default Dna