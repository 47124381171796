import React from 'react';
import classes from "./Testimonialprops.module.css";

const Testimonialprops = (props) => {
    return (
  <div className={classes.container}>
      <div className={classes.card}>
        <img
          src={props.imgg}
          alt="Eshikatech Pvt Ltd"
          className={classes.icon}
        />
        <span className={classes.text}>{props.text}</span>
      </div>
    </div>
      
    )
};

export default Testimonialprops