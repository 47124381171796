import React from "react";
import Slider from "react-slick";
import Rcard from "./Testimonialprops";

import a from "./../../Assest/html.webp";
import b from "./../../Assest/css.webp";
import c from "./../../Assest/js.webp";
import i from "./../../Assest/bootstrap.webp";
import d from "./../../Assest/php.webp";
import e from "./../../Assest/react.webp";
import f from "./../../Assest/nodejs.webp";
import g from "./../../Assest/sql.webp";
import h from "./../../Assest/wordpress.webp";
import j from "./../../Assest/rest.webp";
import k from "./../../Assest/wooc.webp";
import L from "./../../Assest/python.webp";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import classes from "./Testimonials.module.css";
/*
import t1 from "./../../Assests/golde.png";
import t2 from "./../../Assests/siresj.png";
import t3 from "./../../Assests/dipika.png";
import t4 from "./../../Assests/p.png";

*/
const Testimonial = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 8000,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        rtl: true,
        cssEase: 'linear',
        responsive: [
            {
              breakpoint: 768,
              settings: {
                speed: 8000,
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                cssEase: 'linear',
                rtl: true
                
              }
            }
        ]
    };


    

    return (
<div> 

<div className={classes.rsl6}>
<div className={classes.rsl2}>      
                <Slider {...settings}>

<div>
<Rcard imgg={a} text='HTML' ></Rcard>
</div>
<div>
<Rcard imgg={b} text='CSS' ></Rcard>
</div>
<div>
<Rcard imgg={c} text='JS' ></Rcard>
</div>
<div>
<Rcard imgg={i} text='BOOTSTRAP' ></Rcard>
</div>
<div>
<Rcard imgg={d} text='PHP' ></Rcard>
</div>
<div>
<Rcard imgg={e} text='REACT' ></Rcard>
</div>

<div>
<Rcard imgg={f} text='NODE' ></Rcard>
</div>
<div>
<Rcard imgg={g} text='SQL' ></Rcard>
</div>
<div>
<Rcard imgg={h} text='WORDPRESS' ></Rcard>
</div>
<div>
<Rcard imgg={j} text='REST' ></Rcard>
</div>
<div>
<Rcard imgg={k} text='WOOCOMMERCE' ></Rcard>
</div>
<div>
<Rcard imgg={L} text='PYTHON' ></Rcard>
</div>





                </Slider>
                </div>


       </div>     
   </div>    
    );
}

export default Testimonial;
