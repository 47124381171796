import React from 'react'
import classes from "./Ab1.module.css"
import img1 from "./../../Assest/lo.png"
import Fade from 'react-reveal/Fade';

const Ab1 = () => {
  return (
    <div>
 <Fade left>
 
<div className={classes.steps}>

     <br/>
  <h2 className={classes.ih4}>About Us </h2>

<div className={classes.d1}> 
  <p className={classes.ih41}>
    At Eshikatech Pvt Ltd, we have a rich history of creating stunning websites that reflect our client’s unique styles and preferences. Our story began with the vision of our founder, Mrs. Anamika Singh , who has been a pioneer in the field of Web Designing & Development in Mumbai. With a passion for design and a commitment to excellence, we have grown into a leading Web Development company in the city.
We believes in creating an artistic balance between client’s Expectations, project Budget and latest Design Trends to craft spaces that speak for themselves. 
We will provide After Sales Support as we offer the best after-sales support and make any changes they want.
We will Provide Design Suppprt too with more than just style, substance. Let us create a Website for you that truly embodies your style and individuality and helps you to stand uniquely in the market. 
  </p>


</div>

</div>
</Fade>

    </div>
  )
}

export default Ab1